import { Box, Chip, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { color } from '../../Styles/Color'

export const SelectDropdown = ({ value, handleChange, options, placeholder, multiple, defaultItem, isNone = true }) => {

  return (
    <Select
      size='small'
      multiple={multiple || false}
      value={value || (multiple ? [] : "")}
      onChange={handleChange || (() => { })}
      displayEmpty
      input={<OutlinedInput />}
      renderValue={
        (selected, idx) => {
          return multiple ? (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0
                  ?
                  <Typography color={color.secondaryText} fontSize="14px">
                    {placeholder || "Choose a option"}
                  </Typography>
                  :
                  selected.map((value) => (
                    <Chip
                      key={value}
                      sx={{
                        borderRadius: "6px",
                        bgcolor: color.primary,
                        color: color.white,
                        px: 1,
                      }}
                      label={value}
                    />
                  ))}
            </Box>
          ) :
            selected ?
              <Typography variant='body2'>{selected}</Typography> :
              <Typography color={color.secondaryText} variant='body2'>
                {defaultItem || placeholder || "Choose a option"}
              </Typography>
        }
      }
      sx={{ bgcolor: color.white, borderRadius: "8px" }}
    >
      {
        isNone &&
        <MenuItem value="" sx={{ fontSize: "14px" }}>
          {defaultItem || "None"}
        </MenuItem>
      }
      {(options || []).map((option, idx) => (
        <MenuItem
          key={idx}
          value={option}
          sx={{ fontSize: "14px" }}
        >
          {option}
        </MenuItem>
      ))}
    </Select>
  )
}
