import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// import BoltIcon from '@mui/icons-material/Bolt'
import CloudIcon from '@mui/icons-material/Cloud'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
import EditIcon from '@mui/icons-material/Edit'
import LanguageIcon from '@mui/icons-material/Language'
import { Divider, Stack, Typography } from "@mui/material"
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import CustomButton from "../../Components/UiComponents/CustomButton"
import InputField from "../../Components/UiComponents/InputField"
import Loader from '../../Components/UiComponents/Loader'
import { SelectDropdown } from "../../Components/UiComponents/SelectDropdown"
import { PAGE_ROUTES } from '../../Configs/Routes'
import { useGetArtifactStoragePathsQuery } from '../../Services/artifactStorageApi'
import { useGetPriceEstimationMutation } from '../../Services/inferenceApi'
import { color } from "../../Styles/Color"
import DeployModal from './DeployModal'
import EditAutoScaleModal from './EditAutoScaleModal'
import EditInstanceModal from './EditInstanceModal'


export const InferenceTemplateForm = ({
  isModelConfigured,
  isFineTuned,
  handleBack,
  handleSubmit,
  backTitle,
  model,
  setValue,
  watch,
  modelList,
  reset
}) => {

  const isAgentic = useLocation().pathname.includes(PAGE_ROUTES.agenticDashboard)

  const { data: artifactStoragePaths, isLoading: isFetchingPaths } = useGetArtifactStoragePathsQuery()

  const [isInstanceModalOpen, setIsInstanceModalOpen] = useState(false)
  const [isScalingModalOpen, setIsScalingModalOpen] = useState(false)
  const [isDeployModalOpen, setIsDeployModalOpen] = useState(false)

  const [getPrice, { data, isLoading }] = useGetPriceEstimationMutation()

  const cloudProviders = watch('cloud_providers')
  const gpu_type = watch('initial_worker_config.initial_workers_gpu')
  const gpu_count = watch('initial_worker_config.initial_workers_gpu_num')

  useEffect(() => {
    if (gpu_count !== null && gpu_type !== null) {
      getPrice(
        {
          "cloud": cloudProviders.map(cp => cp.name),
          "gpu": [
            {
              "type": gpu_type,
              "count": gpu_count
            }
          ],
          "region": cloudProviders.map(cp => cp.regions).flat()
        }
      )
    }
  }, [gpu_type, gpu_count, getPrice, cloudProviders])

  if (isFetchingPaths) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack direction="row" gap={2} width="100%" >
      <Stack spacing={4} width="55%">
        <Stack spacing={1}>
          <Typography variant="h3">
            {
              model ?
                "Model Name" :
                "Choose a Model"
            }
          </Typography>
          {
            model ?
              <InputField state={watch("model")} disabled={true} placeholder="Enter your model name" /> :
              <SelectDropdown
                options={modelList?.map(m => m.config.model)}
                placeholder="Choose a model"
                value={watch("model")}
                handleChange={(e) => {
                  const config = modelList.find(m => m.config.model === e.target.value).config
                  reset(config)
                }}
                isNone={false}
              />
          }
        </Stack>
        {
          (isFineTuned || isAgentic) &&
          <Stack spacing={1}>
            <Typography variant="h3">Base Model</Typography>
            <InputField
              state={watch("base_model")}
              disabled={true} placeholder="Enter your base model name"
            />
          </Stack>
        }
        {
          !isAgentic ?
            <Stack spacing={1}>
              <Typography variant="h3">HuggingFace Access Token</Typography>
              <InputField
                state={watch("hf_token")}
                setState={(e) => setValue("hf_token", e.target.value)}
                disabled={isModelConfigured} placeholder="Enter your HuggingFace token"
              />
            </Stack> :
            <Stack spacing={1}>
              <Typography variant="h3">Commit Hash</Typography>
              <Typography variant='subtitle1' color={color.secondaryText}>
                Commit hash of the model version you want to deploy. <br />
                Note: By default your latest model version will get deployed. How to get?
                <Typography variant='subtitle1' color={color.primary}>
                  How to get?
                </Typography>
              </Typography>
              <InputField placeholder="e.g. 7c47hfje" />
            </Stack>
        }
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h3">Result Folder</Typography>
          <Typography variant="subtitle1" color={color.secondaryText}>
            Choose an artifacts storage to store logs and inference results.
          </Typography>
          <SelectDropdown
            value={watch && watch("logs_store")}
            handleChange={(e) => setValue && setValue("logs_store", e.target.value)}
            options={artifactStoragePaths?.map(path => path.name)}
            placeholder="Choose a result store"
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            onClick={handleBack}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
          >
            <ArrowBackIcon fontSize='small' />
            <Typography>
              {backTitle}
            </Typography>
          </Stack>
          <CustomButton onClick={handleSubmit}>Deploy Model</CustomButton>
        </Stack>
      </Stack>
      <Stack width="43%" mt={-10} p={1} spacing={2}>
        <Stack
          border={`1px solid ${color.borders}`}
          borderRadius="6px"
          boxShadow="0px 1px 4px 0px #0000000A"
          p={2}
          pl={4}
          spacing={2}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant='h2'>Instance Type</Typography>
            <Stack
              bgcolor={color.lightBlue}
              color={color.primary}
              borderRadius="6px"
              p={1}
              sx={{
                "&:hover": {
                  cursor: "pointer"
                }
              }}
              onClick={() => setIsInstanceModalOpen(true)}
            >
              <EditIcon fontSize='small' />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={1}>
              <DynamicFormIcon fontSize='small' sx={{ color: color.primary }} />
              <Typography variant='body2'>
                {watch('initial_worker_config.initial_workers_gpu_num')}x {watch("initial_worker_config.initial_workers_gpu")}
              </Typography>
            </Stack>

            {
              data ?
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Typography variant='body2'>
                    approx. $ {Math.round(data.on_demand_price.min * 100) / 100}</Typography>
                  <Typography variant='body2' color={color.secondaryText}>/ hr</Typography>
                </Stack> :
                isLoading &&
                <Typography variant='body2' color={color.secondaryText}>
                  calculating estimates....
                </Typography>
            }
          </Stack>
          {/* {<Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            bgcolor={color.secondaryBackground}
            border={`1px solid ${color.borders}`}
            borderRadius="6px"
            p={1}
          >
            <Stack direction="row" gap={1} alignItems="end">
              <BoltIcon fontSize='small' sx={{ color: color.primary }} />
              <Typography variant='body2'>400</Typography>
              <Typography variant='subtitle1' color={color.secondaryText}>tokens/sec</Typography>
            </Stack>
            <Typography color={color.secondaryText} variant='body2'>Expected hroughput</Typography>
          </Stack>} */}
        </Stack>
        <Stack
          border={`1px solid ${color.borders}`}
          borderRadius="6px"
          boxShadow="0px 1px 4px 0px #0000000A"
          p={2}
          pl={4}
          spacing={3}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant='h2'>Deployment Region</Typography>
            <Stack
              bgcolor={color.lightBlue}
              color={color.primary}
              borderRadius="6px"
              p={1}
              sx={{
                "&:hover": {
                  cursor: "pointer"
                }
              }}
              onClick={() => setIsDeployModalOpen(true)}
            >
              <EditIcon fontSize='small' />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={4}>
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" gap={2}>
                <CloudIcon fontSize='small' sx={{ color: color.primary }} />
                <Typography variant='body2'>{watch("controller_cloud_config.cloud_provider")}</Typography>
              </Stack>
              <Typography variant='subtitle1' color={color.secondaryText}>Cloud Provider</Typography>
            </Stack>
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" gap={2}>
                <LanguageIcon fontSize='small' sx={{ color: color.primary }} />
                <Typography variant='body2'>{watch("controller_cloud_config.region")}</Typography>
              </Stack>
              <Typography variant='subtitle1' color={color.secondaryText}>Region</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant='subtitle1' color={color.secondaryText}>
              Select region to deploy the model.
            </Typography>
            <Typography
              variant='subtitle1'
              color={color.primary}
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => window.open("https://docs.scalegen.ai/inf-guide#deployment-region", "_blank")}
            >
              Learn more
            </Typography>
          </Stack>
        </Stack>
        <Stack
          border={`1px solid ${color.borders}`}
          borderRadius="6px"
          boxShadow="0px 1px 4px 0px #0000000A"
          p={2}
          pl={4}
          spacing={3}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant='h2'>Autoscaling</Typography>
            <Stack
              bgcolor={color.lightBlue}
              color={color.primary}
              borderRadius="6px"
              p={1}
              sx={{
                "&:hover": {
                  cursor: "pointer"
                }
              }}
              onClick={() => setIsScalingModalOpen(true)}
            >
              <EditIcon fontSize='small' />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" >
            {
              !isAgentic ?
                <Stack spacing={1} width="50%">
                  <Typography variant='subtitle1' color={color.secondaryText}>
                    {
                      watch("autoscaling_config.autoscaling_strategy") === "ttft_latency_sec" ?
                        "TTFT Latency" : "RPS per worker"
                    }
                  </Typography>
                  <Typography variant='body2'>
                    {watch("autoscaling_config.upper_allowed_threshold")} {
                      watch("autoscaling_config.autoscaling_strategy") === "ttft_latency_sec" ?
                        "sec" : "requests / sec"
                    }
                  </Typography>
                </Stack> :
                <Stack spacing={1} width="50%">
                  <Typography variant='subtitle1' color={color.secondaryText}>Requests per second</Typography>
                  <Typography variant='body2'>1 requests</Typography>
                </Stack>
            }
            <Stack spacing={1} width="50%">
              <Typography variant='subtitle1' color={color.secondaryText}>Scale-Up Window</Typography>
              <Typography variant='body2'>{watch("autoscaling_config.scale_up_time_window_sec")} sec</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" >
            <Stack spacing={1} width="50%">
              <Typography variant='subtitle1' color={color.secondaryText}>Scale-Down window</Typography>
              <Typography variant='body2'>
                {watch("autoscaling_config.scale_down_time_window_sec")} sec
              </Typography>
            </Stack>
            <Stack spacing={1} width="50%">
              <Typography variant='subtitle1' color={color.secondaryText}>Scale-to-Zero</Typography>
              <Typography variant='body2'>
                {
                  watch("autoscaling_config.scale_to_zero") ?
                    `${watch("autoscaling_config.scale_to_zero_timeout_sec")} sec`
                    : "Disabled"
                }
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" >
            <Stack spacing={1} width="50%">
              <Typography variant='subtitle1' color={color.secondaryText}>Min Replicas</Typography>
              <Typography variant='body2'>
                {watch("initial_worker_config.min_workers")}
              </Typography>
            </Stack>
            <Stack spacing={1} width="50%">
              <Typography variant='subtitle1' color={color.secondaryText}>Max Replicas</Typography>
              <Typography variant='body2'>
                {watch("initial_worker_config.max_workers")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Typography variant='subtitle1' color={color.secondaryText}>
          *Compute and auto-scaling configurations are pre-filled from base model template. Modify them as per your requirements before deploying.
        </Typography>
      </Stack>
      <DeployModal
        isOpen={isDeployModalOpen} setIsOpen={setIsDeployModalOpen}
        watch={watch} setValue={setValue}
      />
      <EditInstanceModal
        isOpen={isInstanceModalOpen} setIsOpen={setIsInstanceModalOpen}
        watch={watch} setValue={setValue} currentEstimate={data?.on_demand_price?.min}
      />
      <EditAutoScaleModal
        watch={watch} setValue={setValue}
        isOpen={isScalingModalOpen} setIsOpen={setIsScalingModalOpen}
      />
    </Stack >
  )
}
