import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
import EditIcon from '@mui/icons-material/Edit'
import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../Components/UiComponents/CustomButton'
import InputField from '../../Components/UiComponents/InputField'
import { SelectDropdown } from '../../Components/UiComponents/SelectDropdown'
import { useGetPriceEstimationMutation } from '../../Services/inferenceApi'
import { color } from '../../Styles/Color'
import EditInstanceModal from '../Inference/EditInstanceModal'
import { DataConfig } from './DataConfig'
import EditAutoTrainModal from './EditAutoTrainModal'
import { StorageConfig } from './StorageConfig'
import { TrackingConfig } from './TrackingConfig'

export const FinetuneTemplateForm = ({
  isBaseModel, handleBack, handleSubmit, backTitle, watch, setValue, modelList, reset, errors
}) => {

  const [isInstanceModalOpen, setIsInstanceModalOpen] = useState(false)
  const [isAutoTrainModal, setIsAutoTrainModal] = useState(false)

  const [getPrice, { data, isLoading }] = useGetPriceEstimationMutation()

  const cloudProviders = watch('cloud_providers')
  const gpu_type = watch('gpu_type')
  const gpu_count = watch('gpu_count')

  useEffect(() => {
    if (gpu_count !== null && gpu_type !== null) {
      getPrice(
        {
          "cloud": cloudProviders.map(cp => cp.name),
          "gpu": [
            {
              "type": gpu_type,
              "count": gpu_count
            }
          ],
          "region": cloudProviders.map(cp => cp.regions).flat()
        }
      )
    }
  }, [gpu_type, gpu_count, getPrice, cloudProviders])


  return (
    <Stack direction="row" gap={2} width="100%" >
      <Stack spacing={1} width="49%">
        <Stack
          maxHeight="62vh"
          overflow="auto"
          // sx={{
          //   '&::-webkit-scrollbar': {
          //     display: 'none',
          //   }
          // }}
          spacing={4}
          pb={1}
          pr={1}
        >
          <Stack spacing={3}>
            <Typography variant="h2">Model Configuration</Typography>
            {
              <Stack
                spacing={3}
                borderRadius="12px"
                border={`1px solid ${color.borders}`}
                box-shadow="0px 1px 4px 0px #0000000A"
                p={3}
              >
                {
                  isBaseModel ?
                    <Stack spacing={1}>
                      <Typography variant="h3">
                        Choose a Model
                      </Typography>
                      <SelectDropdown
                        value={watch('model')}
                        handleChange={e => {
                          const config = modelList.find(m => m.config.model === e.target.value).config
                          reset(config)
                        }}
                        options={
                          modelList.map(m => m.config.model)
                        }
                        placeholder="Choose a model"
                        isNone={false}
                      />
                    </Stack> :
                    <>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Model Name
                        </Typography>
                        <InputField
                          state={watch('model')}
                          setState={e => setValue('model', e.target.value)}
                          disabled={true} placeholder="Enter your model name"
                        />
                      </Stack>
                      <Stack spacing={1}>
                        <Typography variant="h3">Base Model</Typography>
                        <InputField
                          state={watch('base_model')}
                          setState={e => setValue('base_model', e.target.value)}
                          disabled={true} placeholder="Enter your base model name"
                        />
                      </Stack>
                    </>
                }
                <Stack spacing={2}>
                  <Typography variant="h3">HuggingFace Access Token</Typography>
                  <InputField
                    state={watch('autotrain_params.hf_token')}
                    setState={(e) => setValue('autotrain_params.hf_token', e.target.value)}
                    placeholder="Enter your HuggingFace token"
                  />
                </Stack>
              </Stack>
            }
          </Stack>
          <DataConfig
            watch={watch} setValue={setValue} errors={errors}
          />
          <StorageConfig
            watch={watch} setValue={setValue} errors={errors}
          />
          <TrackingConfig
            watch={watch} setValue={setValue}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
          <Stack
            direction="row"
            gap={1}
            color={color.primary}
            alignItems="center"
            onClick={handleBack}
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
          >
            <ArrowBackIcon fontSize='small' />
            <Typography>
              {backTitle}
            </Typography>
          </Stack>
          <CustomButton onClick={handleSubmit}>Launch Job</CustomButton>
        </Stack>
      </Stack>
      <Stack width="49%" mt={-10} p={1} spacing={2}>
        <Stack
          border={`1px solid ${color.borders}`}
          borderRadius="6px"
          boxShadow="0px 1px 4px 0px #0000000A"
          p={2}
          pl={4}
          spacing={3}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant='h2'>GPU Configuration</Typography>
            <Stack
              bgcolor={color.lightBlue}
              color={color.primary}
              borderRadius="6px"
              p={1}
              onClick={() => setIsInstanceModalOpen(true)}
              sx={{
                "&:hover": {
                  cursor: "pointer"
                }
              }}
            >
              <EditIcon fontSize='small' />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={1}>
              <DynamicFormIcon fontSize='small' sx={{ color: color.primary }} />
              <Typography variant='body2'>{watch('gpu_count')}x {watch('gpu_type')}</Typography>
            </Stack>
            {
              data ?
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Typography variant='body2'>
                    approx. $ {Math.round(data.on_demand_price.min * 100) / 100}
                  </Typography>
                  <Typography variant='body2' color={color.secondaryText}>/ hr</Typography>
                </Stack> :
                isLoading &&
                <Typography variant='body2' color={color.secondaryText}>
                  calculating estimates....
                </Typography>
            }
          </Stack>
        </Stack>
        <Stack
          border={`1px solid ${color.borders}`}
          borderRadius="6px"
          boxShadow="0px 1px 4px 0px #0000000A"
          p={2}
          pl={4}
          spacing={3}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="end" gap={1}>
              <Typography variant='h2'>AutoTrain Parameters</Typography>
              <Typography
                variant='body2'
                color={color.primary}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => window.open("https://docs.scalegen.ai/ft-guide#autotrain-parameters", "_blank")}
              >
                Learn more
              </Typography>
            </Stack>
            <Stack
              bgcolor={color.lightBlue}
              color={color.primary}
              borderRadius="6px"
              p={1}
              onClick={() => setIsAutoTrainModal(true)}
              sx={{
                "&:hover": {
                  cursor: "pointer"
                }
              }}
            >
              <EditIcon fontSize='small' />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" >
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Epochs</Typography>
              <Typography variant='body2'>{watch('autotrain_params.epochs')}</Typography>
            </Stack>
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Learning Rate</Typography>
              <Typography variant='body2'>{watch('autotrain_params.lr')}</Typography>
            </Stack>
            <Stack spacing={1} width="30%">
              <Typography variant='subtitle1' color={color.secondaryText}>Batch Size</Typography>
              <Typography variant='body2'>{watch('autotrain_params.batch_size')}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" >
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Block Size</Typography>
              <Typography variant='body2'>{watch('autotrain_params.block_size')}</Typography>
            </Stack>
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Model Max Length</Typography>
              <Typography variant='body2'>{watch('autotrain_params.model_max_length')}</Typography>
            </Stack>
            <Stack spacing={1} width="30%">
              <Typography variant='subtitle1' color={color.secondaryText}>Seed</Typography>
              <Typography variant='body2'>{watch('autotrain_params.seed')}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" >
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Gradient Accumulation</Typography>
              <Typography variant='body2'>{watch('autotrain_params.gradient_accumulation_steps')}</Typography>
            </Stack>
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Mixed Precision</Typography>
              <Typography variant='body2'>{watch('autotrain_params.mixed_precision') || "None"}</Typography>
            </Stack>
            <Stack spacing={1} width="30%">
              <Typography variant='subtitle1' color={color.secondaryText}>Quantization</Typography>
              <Typography variant='body2'>{watch('autotrain_params.quantization') || "None"}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" >
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Torch dType</Typography>
              <Typography variant='body2'>{watch('autotrain_params.torch_dtype') || "None"}</Typography>
            </Stack>
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Gradient Checkpointing</Typography>
              <Typography variant='body2'>
                {
                  watch('autotrain_params.disable_gradient_checkpointing') ? "Enabled" : "Disabled"
                }
              </Typography>
            </Stack>
            <Stack spacing={1} width="30%">
              <Typography variant='subtitle1' color={color.secondaryText}>FlashAttention2</Typography>
              <Typography variant='body2'>
                {
                  watch('autotrain_params.use_flash_attention_2') ? "Enabled" : "Disabled"
                }
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" >
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>LoRA</Typography>
              <Typography variant='body2'>
                {
                  watch('autotrain_params.use_peft') === "lora" ? "Enabled" : "Disabled"
                }
              </Typography>
            </Stack>
            <Stack spacing={1} width="35%">
              <Typography variant='subtitle1' color={color.secondaryText}>Use Deepspeed</Typography>
              <Typography variant='body2'>
                {watch('autotrain_params.use_deepspeed') || "None"}
              </Typography>
            </Stack>
          </Stack>
          {
            watch('autotrain_params.use_peft') === "lora" &&
            <Stack direction="row" alignItems="center" >
              <Stack spacing={1} width="35%">
                <Typography variant='subtitle1' color={color.secondaryText}>r</Typography>
                <Typography variant='body2'>{watch('autotrain_params.lora_r')}</Typography>
              </Stack>
              <Stack spacing={1} width="35%">
                <Typography variant='subtitle1' color={color.secondaryText}>Alpha</Typography>
                <Typography variant='body2'>{watch('autotrain_params.lora_alpha')}</Typography>
              </Stack>
              <Stack spacing={1} width="30%">
                <Typography variant='subtitle1' color={color.secondaryText}>Dropout</Typography>
                <Typography variant='body2'>{watch('autotrain_params.lora_dropout')}</Typography>
              </Stack>
            </Stack>
          }

        </Stack>
      </Stack>
      <EditInstanceModal
        isOpen={isInstanceModalOpen} setIsOpen={setIsInstanceModalOpen}
        watch={watch} setValue={setValue} currentEstimate={data?.on_demand_price?.min}
      />
      <EditAutoTrainModal
        isOpen={isAutoTrainModal} setIsOpen={setIsAutoTrainModal}
        watch={watch} setValue={setValue}
      />
    </Stack>
  )
}
